<template>
  <div class="animated fadeIn">

    <b-row class="card-container">
      <b-col sm="12">
        <b-card header-tag="header" class="card-container">
          <div slot="header"><i class="icon-dollars"></i>Games list</div>
          <div>
            <b-row>
              <b-row class="card-container">
                 <b-col v-for="(card, index) in gameCards" :key="index" cols="12" sm="6" md="4" lg="3" class="mb-4">
                    <b-card class="custom-card">
                        <div slot="header"><i class="icon-dollars"></i>{{card.appName}}</div>

                        <b-card-body class="custom-card-body">
                          <b-img :src= "card.media.tile" alt="Card image" class="custom-card-img" />
                        </b-card-body>
                        <div class="custom-card-footer">
                            <b-button
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    variant="primary mt-3"
                                    size="lg"
                                    :href="card.playUrl"
                                    v-on:click="handlePlayGamesButtonClick"
                                    >Play Instantly!</b-button
                                  >
                        </div>
                    </b-card>
                </b-col>
              </b-row>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import Vue from "vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};

miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);

export default {
  name: "Roblox",
  mounted() {
    if (this.$ga) {
      this.$ga.event("now-gg-game-new-tab", "open:roblox-tab", "");
    }
  },
  data() {
    return {
      getCopyCommandText: `/games roblox`,
      getCopyToGCommandText: `/games tog`,
      gameCards: []
    };
  },
  notifications: {
    showCopyCommandSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Command copied to clipboard"
    }
  },
  methods: {
    handleCopyCommandButtonClick() {
      if (this.$ga) {
        this.$ga.event("now-gg-game-new-tab", "click:copy-command", "");
      }
    },
    handlePlayButtonClick() {
      if (this.$ga) {
        this.$ga.event("now-gg-game-new-tab", "click:button:play-roblox", "");
      }
    },
    handlePlayToGButtonClick() {
      if (this.$ga) {
        this.$ga.event("now-gg-game-new-tab", "click:button:play-tog", "");
      }
    },
    handlePlayGamesButtonClick() {
      if (this.$ga) {
        this.$ga.event("now-gg-game-new-tab", "click:button:play-games", "");
      }
    },
  },
  computed: {
    current_guild() {
      return this.$store.state.discord_guilds.find(
        x => x.id == this.$route.params.guild_id
      );
    }
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/games")
      .then(r => {
        this.gameCards = r.data.games;
        this.$Progress.finish();
      });
  }
};
</script>

<style src="spinkit/spinkit.min.css"></style>

<style scoped>
div.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 320px;
  height: 10vh;
}
img.item {
  width: 100%;
  max-width: 200px;
}

img.showoff {
  max-width: 100%;
}

.caption {
  display: block;
}

.shartCommand {
  color: #fefefe;
  font-family: poppins;
  font-size: 16px;
  margin: 20px 0 0 0;
  background: #343b41;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.custom-card {
  display: flex;
  flex-direction: column;
  border: 3px solid #2C2C29;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent overflow */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.custom-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-card-img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.custom-card-body {
  flex: 1;
  padding: 0.1rem;
}

.custom-card-title {
  font-size: 1.25rem;
  height: 3rem;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-card-text {
  font-size: 1rem;
}

.custom-card-footer {
  padding: 1rem;
  text-align: center;
  margin-top: auto;
  display: flex;
  justify-content:center;
}

.tog-img-row {
height: 44.2vh;
overflow: hidden;
}

.tog-img-row img {
  height: 44.2vh;
  object-fit: scale-down;
}

.card-container{
height: 100%;
width: 100%;
}
</style>
